import React, { useRef } from "react"
import {graphql, Link} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Helmet from '../../../components/Helmet';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import BarEnd from '../../../images/svg/barend.inline.svg'
import LtGreenCube from '../../../images/svg/LT_GREEN_cube.inline.svg'
import OrangeCube from '../../../images/svg/ORANGE_cube.inline.svg'
import { CgArrowLongLeftC, CgArrowLongRightC } from 'react-icons/cg'

import '../../../styles/WorkProjectTemplateStyles.scss'

const WorkProjectTemplate = ({ data }) => {
  // console.log("Data on Worunit.Json.slug: ", data);

  const {
    jsonId,
    slug,
    title,
    subTitle,
    alt,
    // image,
    role,
    bulletPoints,
    description,
    htmlCopy,
    // featured,
    projectImgs,
    tags
  } = data.workunitJson;

  // const projectThumb = getImage(image.childImageSharp.gatsbyImageData)


const projects = data.allWorkunitJson.edges
const projectPagination = projects.filter((item => jsonId === item.node.jsonId))
const definitionDisplay = useRef()


  const tagRolloverHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const defText = event.currentTarget.getAttribute("data-def")
    definitionDisplay.current.innerHTML = defText
  }
  const tagRollOutHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    definitionDisplay.current.innerHTML = ""
  }

  function makeHTML(htmlContent) { return {__html: htmlContent}; };

  function PagePrevious() {
    const previousObj = projectPagination[0].previous
    if(previousObj !== null){
        return (
          <Link className="page-previous" aria-label="navigate to previous project"
                to={`/development/project/${previousObj.slug}`} title="Previous Project">
            <CgArrowLongLeftC className="page-arrow" />
          </Link>
        )
    }
    return null
  }
  function PageNext() {
    const nextObj = projectPagination[0].next
    if(nextObj !== null){
        return (
          <Link className="page-next" aria-label="navigate to next project"
                to={`/development/project/${nextObj.slug}`} title="Next Project">
              <CgArrowLongRightC className="page-arrow" />
          </Link>
        )  
    }
    return null
  }
  
  function PaginationButtons() {
    return  (
      <>
      <PagePrevious /><PageNext />
      </>
    ) 
  }

  return (
    <>
		<Helmet title={`Development Project: ${title}`} />
		<Header subNav={true} />

		<main id="development-project">
			<div className="container">
				<section className="section container">
        <div className="content" id="main-content">

          <div className="top-title">
              <LtGreenCube aria-hidden="true" />
              <h1 className="h1"><span>Development Work</span></h1>
          </div>
          <div className="pagination">
                <PaginationButtons />
          </div>
                
          <div className="module-work-unit">
                <div className="featured-backdrop">
                    <div className="work-unit">
                        <div className="project-header">
                            <div className="project-title">
                            <h2 className="h2">{title}</h2>
                            <p>{subTitle}</p>
                            </div>
                            <div className="work-tags" role="group" aria-label="Technology used in this project">
                                <dfn id={`acc-${jsonId}`} className="definition" ref={definitionDisplay} aria-live="polite"></dfn>
                                <ul>
                                {tags.map((tag, index)=>{
                                    return (
                                        <li key={""+index}>
                                                <abbr 
                                                   data-def={tag.definition} 
                                                   onMouseEnter={tagRolloverHandler} 
                                                   onMouseOut={tagRollOutHandler}
                                                   onFocus={tagRolloverHandler} 
                                                   aria-describedby={`acc-${jsonId}`}
                                                   tabIndex="0"
                                                >{tag.name}</abbr>
                                        </li>
                                    )
                                })}
                                </ul>
                            </div>
                        </div>
    
                        <div className="project-content">
                            <div className="project-description">
                                <h3 className="h3">{role}</h3>
                                <div dangerouslySetInnerHTML={makeHTML(description)} />
                                <ul>
                                    {bulletPoints.map((point, index)=>{
                                        return (
                                            <li key={""+index}>{point}</li>
                                        )
                                    })}
                                </ul>

                                <div dangerouslySetInnerHTML={makeHTML(htmlCopy)} />

                            </div>
                            <div id={`${slug}-assets`} className="project-assets">
                              <span className="sr-only">A gallery of screenshots</span>
                              { projectImgs ? 
                                projectImgs.map((image, index) => {
                                  
                                    const currentImage = getImage(image.src)
                                  
                                    return (
                                      <div key={""+index} className={`area area-${index}`}>
                                      <GatsbyImage image={currentImage} alt={alt} className="area-img" />
                                      </div>
                                    )
                                })
                                : ""
                              }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pagination">
              <PaginationButtons />
            </div>

          <div className="bot-title">
              <OrangeCube />
              <div  className="more-link">
                  <Link to="/development"><span className="sr-only">View </span>All Web<span> development work</span> <BarEnd aria-hidden="true" /></Link>  
              </div>
          </div>
        

        </div>
        </section>
      </div>
		</main>
		<Footer />
	</>
  )
}

export const query = graphql`
query MyQuery($slug: String) {
  workunitJson(slug: {eq: $slug}) {
    jsonId
    title
    slug
    subTitle
    role
    featured
    description
    htmlCopy
    bulletPoints
    alt
    tags {
      class
      definition
      definitionDescription
      name
    }
    image {
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          quality: 95
          tracedSVGOptions: {background: "#2C3D44"}
        )
      }
    }
    projectImgs {
      src {
        childImageSharp {
          gatsbyImageData(
            quality: 95 
            placeholder: TRACED_SVG
            tracedSVGOptions: {background: "#2C3D44"}
          )
        }
      }
    }
  }
  allWorkunitJson {
    edges {
      previous {
        jsonId
        slug
      }
      node {
        jsonId
        slug
      }
      next {
        jsonId
        slug
      }
    }
  }
}

`


export default WorkProjectTemplate